<template>
  <a-modal v-model="modalVisible" :title="modalTitle" :width="1000" :centered="true" :body-style="modalBodyStyle">
    <template slot="footer">
      <a-button type="primary" @click="closeModal">关闭</a-button>
    </template>
    <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns detail-form-bg" :model="formData" :label-col="{span:9}" :wrapper-col="{span:14}">
      <a-tabs type="card">
        <a-tab-pane key="1" tab="巡查信息">
          <h3 style="font-weight: bold; color: #333;">巡查信息</h3>
          <a-form-model-item label="巡查点编号" prop="point_num">
            <span>{{formData.point_num}}</span>
          </a-form-model-item>
          <a-form-model-item label="巡查点名称" prop="point_name">
            <span>{{formData.point_name}}</span>
          </a-form-model-item>
          <a-form-model-item label="巡查点类型" prop="point_type">
            <span>{{pointTypes[formData.point_type]}}</span>
          </a-form-model-item>
          <a-form-model-item label="巡查人员" prop="register_person">
            <span>{{formData.username}}</span>
          </a-form-model-item>
          <a-form-model-item label="签到时间" prop="inspection_time">
            <span>{{formData.inspection_time ? moment(formData.inspection_time, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
          </a-form-model-item>
          <a-form-model-item label="签到地点" prop="inspection_address">
            <span>{{formData.inspection_address}}</span>
          </a-form-model-item>
          <a-form-model-item label="结束时间" prop="inspection_end_time">
            <span>{{formData.inspection_end_time ? moment(formData.inspection_end_time, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
          </a-form-model-item>
          <a-form-model-item label="该点位耗时" prop="inspection_interval">
            <span>{{formData.inspection_interval}}</span>
          </a-form-model-item>
          <a-form-model-item label="巡查结论" prop="result_code">
            <span>{{formData.result_code?resultCodes[formData.result_code]:""}}</span>
          </a-form-model-item>
          <a-form-model-item label="关联摄像机名称/编号" prop="monitor_num">
            <span>{{formData.monitor_num}}</span>
          </a-form-model-item>
          <a-form-model-item label="备注" prop="remark">
            <span>{{formData.remark}}</span>
          </a-form-model-item>
          <a-form-model-item v-if="formData.inspection_exception!=='0'" label="巡查异常" prop="inspection_exception">
            <span style="color: red">{{formData.inspection_exception==='3'?"图片拍摄时间和巡查时间相差超过24小时":formData.inspection_exception==='2'?"本次巡查前后两点之间的时长大于该两点平均巡查时长的2倍":"本次巡查签到时间不在巡查线路规定的最早起始时间和最晚结束时间之间"}}</span>
          </a-form-model-item>
          <h3 style="font-weight: bold; color: #333;">巡查图片/视频</h3>
          <div class="picture-list" style="display: flex">
            <div class="picture-line" v-for="(item, index) in cameraList" :key="index" style="margin-left: 15%">
              <h3>{{item.picTypeName}}：<span v-if="item.time_anomaly==='1'" style="color: red;font-size: small"><span style="font-size: large">时间异常</span> （图片拍摄时间和巡查时间相差超过24小时）</span></h3>
              <div v-if="item.pic_type == '16' || item.pic_type == '22'" style="overflow: hidden;padding-bottom: 8px;">
                <video v-for="(cItem, cIndex) in item.list" :key="cIndex" :src="cItem.url" controls="controls"></video>
              </div>
              <a-upload v-else list-type="picture-card" :file-list="item.list" disabled></a-upload>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="巡查条目">
          <a-table :columns="tableColumns" :data-source="tableData" row-key="item_record_id" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" :pagination="false">
            <span slot="index" slot-scope="value, record, index">
              {{index+1}}
            </span>
            <span slot="item_status" slot-scope="value">
              {{engineerInspectionItemStatus[value]}}
            </span>
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </a-form-model>
    <add-or-edit-modal :visible.sync="equipmentModalVisible" :show-type="equipmentModalShowType" :ActiveKey="equipmentModalActiveKey" :detailData="equipmentModalDetailData"></add-or-edit-modal>
  </a-modal>
</template>
<script>
import moment from 'moment'
import {engineerInspectionItemStatus, pointTypes, resultCodes} from "@/json/patrol";
import {getPropertyInspectionItemRecordListByOrderId, getPropertyInspectionPicByRecordId} from "A/patrol";
import {inspectionPicType, maintenancePicType} from "@/json/maintenance";
import {getCache, getItemFromArrayByKey} from "U/index";
import addOrEditModal from "V/facilityAndEquipment/basicManagement/directories/addOrEditModal";
import {mapGetters} from "vuex";
export default {
  props: {
    visible: {
      default: false
    },
    detailData: {
      default: null
    }
  },
  components: {
    addOrEditModal,
  },
  data() {
    return {
      moment,
      pointTypes,
      resultCodes,
      engineerInspectionItemStatus,
      modalVisible: false,
      modalBodyStyle: {
        height: '580px',
        overflowY: 'auto',
      },
      formData: {
        monitorpointnum: '',
        monitorpointname: '',
        equipment_num: '',
        equipment_name: '',
        machine_room_num: '',
        machine_room_name: '',
        username: '',
        inspection_time: '',
        inspection_address: '',
        inspection_end_time:'',
        result_code:'',
        remark:'',
        inspection_interval:'',
        inspection_exception:'',
      },
      tableColumns: [
        {
          title: '序号',
          key: 'index',
          align: 'center',
          scopedSlots: { customRender: 'index' }
        },
        {
          title: '项目名称',
          dataIndex: 'item_name',
          key: 'item_name',
          ellipsis: true,
        },
        {
          title: '状态',
          dataIndex: 'item_status',
          key: 'item_status',
          scopedSlots: { customRender: 'item_status' }
        },
        // {
        //   title: '检查结果',
        //   dataIndex: 'result_desc',
        //   key: 'result_desc',
        //   ellipsis: true,
        // },
      ],
      tableData: [],
      deviceCodeList:[],
      equipmentStatusList:[],
      equipmentCategoryMap:{},
      equipmentCategoryList:[],
      equipmentChildCategoryList:[],
      equipmentChildCategoryMap:[],
      equipmentTableColumns: [
        {
          title: '序号',
          key: 'index',
          align: 'center',
          width:60,
          scopedSlots: { customRender: 'index' }
        },
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '设备类型',
          dataIndex: 'equipment_type',
          key: 'equipment_type',
          ellipsis: true,
          width:80,
          scopedSlots: { customRender: 'equipment_type' },
        },
        {
          title: '设备编号',
          dataIndex: 'equipment_num',
          key: 'equipment_num',
          ellipsis: true,
          scopedSlots: { customRender: 'equipment_num' },
        },
        {
          title: '设备名称',
          dataIndex: 'equipment_name',
          key: 'equipment_name',
          ellipsis: true,
        },
        {
          title: '设备类别',
          dataIndex: 'equipment_category',
          key: 'equipment_category',
          ellipsis: true,
          scopedSlots: { customRender: 'equipment_category' },
        },
        {
          title: '设备子类别',
          dataIndex: 'equipment_child_category',
          key: 'equipment_child_category',
          ellipsis: true,
          scopedSlots: { customRender: 'equipment_child_category' },
        },
        {
          title: '详情',
          dataIndex: 'detail',
          key: 'detail',
          ellipsis: true,
          width:60,
          scopedSlots: { customRender: 'detail' },
        },
      ],
      equipmentTableData: [],
      equipmentItemTableData:[],
      cameraList: [],
      activeKey:[],
      equipmentModalActiveKey:"1",
      equipmentModalVisible: false,
      equipmentModalShowType: '',
      equipmentModalDetailData: null,

      //操作按钮权限
      btnList:[],
      menu:{},
    }
  },
  computed: {
    ...mapGetters(['operationMenuTree']),
    modalTitle() {
      return '详情'
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.initDetail();
        this.getTableData();
        this.getPictureList();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetData();
        this.$emit('update:visible', false);
      }
    },
    activeKey(key) {
    },
  },
  created() {
    this.menu=getItemFromArrayByKey(this.operationMenuTree,"menuid",this.$route.params.menuid,"children")
    for(let i=0;i<this.menu.children.length;i++){
      this.btnList.push(this.menu.children[i].menuname)
    }
    this.modalVisible = this.visible;
    let dictionary = getCache('dictionary', true);
    if(dictionary) {
      this.deviceCodeList = dictionary.deviceCodeList
      this.equipmentStatusList = dictionary.equipmentStatusList
      this.equipmentCategoryList = dictionary.equipmentCategoryList
      this.equipmentCategoryMap = dictionary.equipmentCategoryMap
      this.equipmentChildCategoryList = dictionary.equipmentChildCategoryList
      this.equipmentChildCategoryMap = dictionary.equipmentChildCategoryMap
    }
  },
  methods: {
    handleClick(event,record) {
      event.stopPropagation();
      this.showModal(record);
    },
    initDetail() {
      if(this.detailData && this.detailData.inspection_id) {
        this.$nextTick(() => {
          this.formData = this.detailData
        })
      }
    },
    resetData() {
      // this.formData.inspection_id = '';
      this.tableData = [];
      this.cameraList = [];
    },
    getTableData() {
      let params = {
        inspection_id: this.detailData.inspection_id,
        inspection_item_type:1,
      };
      getPropertyInspectionItemRecordListByOrderId(params).then(res => {
        if(res && res.returncode == '0') {
          this.tableData = res.item;
        }
      })
    },
    getPictureList() {
      let params = {
        inspection_id: this.detailData.inspection_id
      };
      getPropertyInspectionPicByRecordId(params).then(res => {
        if(res && res.returncode == '0') {
          let cameraListMap = {};
          res.item.forEach((item, index) => {
            if(!cameraListMap[item.pic_type]) {
              cameraListMap[item.pic_type] = [];
            }
            let obj = {
              ...item,
              uid: 'uid-'+item.pic_type+'-'+index,
              status: 'done',
              url: item.pic_url,
            };
            let nameIndex = item.pic_url.lastIndexOf('/');
            if(nameIndex < 0) {
              nameIndex = item.pic_url.lastIndexOf('\\');
            }
            if(nameIndex > -1) {
              obj.name = item.pic_url.substr(nameIndex+1);
            }
            obj.picTypeName = inspectionPicType[item.pic_type] || '';
            cameraListMap[item.pic_type].push(obj);
          })
          for(let key in cameraListMap) {
            this.cameraList.push({
              pic_type: cameraListMap[key][0].pic_type,
              picTypeName: cameraListMap[key][0].picTypeName,
              list: cameraListMap[key],
              time_anomaly:cameraListMap[key][0].time_anomaly
            })
          }
        }
      })
    },
    closeModal(){
      this.modalVisible=false
    },
    showModal(record){
      this.equipmentModalDetailData=record
      this.equipmentModalShowType="detail"
      this.equipmentModalActiveKey="1"
      this.equipmentModalDetailData.btnList=this.btnList
      this.equipmentModalVisible=true
    },
  }
}
</script>
<style lang="scss" scoped>
.picture-list {
  padding-top: 5px;
  .picture-line {
    margin-top: 10px;
    // border-top: solid 1px #e8e8e8;
  }
  video {
    float: left;
    width: 104px;
    margin-right: 8px;
  }
}
</style>