<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner" style="margin: 10px -10px 10px -10px">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="巡查点名称" prop="point_name" style="width: 25%">
              <a-input v-model="queryParams.point_name"></a-input>
            </a-form-model-item>
            <a-form-model-item label="查询时段" prop="queryInstallDate" style="width: 25%">
              <a-range-picker v-model="queryInstallDate" :default-value="[moment(`${queryParams.starttime.substring(0,10)}`, 'YYYY-MM-DD'), moment(`${queryParams.endtime.substring(0,10)}`, 'YYYY-MM-DD')]"></a-range-picker>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
            <div v-show="showAdvanced" class="advanced-query">
              <a-form-model-item label="巡查人员" prop="username" style="width: 25%">
                <a-select v-model="queryParams.username">
                  <a-select-option v-for="(item, index) in usernameList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="巡查结论" prop="result_code" style="width: 25%">
                <a-select v-model="queryParams.result_code">
                  <a-select-option v-for="(item, index) in resultCodesList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="巡查时长" prop="inspection_interval" style="width: 25%">
                <a-select v-model="queryParams.inspection_interval">
                  <a-select-option value="0~30分钟">0~30分钟</a-select-option>
                  <a-select-option value="30分钟~1小时">30分钟~1小时</a-select-option>
                  <a-select-option value="1小时~3小时">1小时~3小时</a-select-option>
                  <a-select-option value="3小时~1天">3小时~1天</a-select-option>
                  <a-select-option value="大于1天">大于1天</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="作业时间状态" prop="inspection_exception" style="width: 25%">
                <a-select v-model="queryParams.inspection_exception">
                  <a-select-option value="">所有</a-select-option>
                  <a-select-option value="0">正常</a-select-option>
                  <a-select-option value="1">异常</a-select-option>
                </a-select>
              </a-form-model-item>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
            <!--            <a-button type="primary" @click="showModal()"><a-icon type="plus"></a-icon>新建</a-button>-->
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :customRow="customRow" :data-source="tableData" row-key="inspection_id" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="inspection_time" slot-scope="value, record">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="inspection_exception" slot-scope="value">
            <a-tag :color="InspectionExceptionColors[value]||''">{{InspectionException[value]||''}}</a-tag>
          </span>
          <span slot="result_code" slot-scope="value, record">
            <span>{{value?resultCodes[value]:""}}</span>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.inspection_id">详情</a-menu-item>
                <!--                <a-menu-item :key="'edit-'+record.inspection_id">修改</a-menu-item>-->
                <!--                <a-menu-item :key="'delete-'+record.inspection_id">修改</a-menu-item>-->
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <detail-modal :visible.sync="modalVisible" :detail-data="modalDetailData" @get-operation-result="getTableData"></detail-modal>
  </page-container>
</template>
<script>
import { getItemFromArrayByKey, getTimeRange } from 'U'
import { mapState } from 'vuex'
import moment from 'moment'
import deptselect from '@/mixins/deptselect'
import pagination from '@/mixins/pagination'
import {
  deletePropertyInspection,
  getPropertyInspectionListByCondition, getPropertyInspectionUsernameList,
} from "A/patrol";
import DetailModal from "V/propertyInspection/inspectionRecord/DetailModal";
import {InspectionException, InspectionExceptionColors, resultCodes, resultCodesList} from "@/json/patrol";
export default {
  name: 'PropertyPatrolRecord',
  mixins: [deptselect,pagination],
  components: {
    DetailModal,
  },
  props: ['pageType'],
  data() {
    return {
      moment,
      resultCodes,
      resultCodesList,
      InspectionException,
      InspectionExceptionColors,
      usernameList:[],
      showAdvanced: false,
      queryParams: {
        monitorpointname:'',
        point_name:'',
        username:'',
        result_code:'',
        inspection_interval:'',
        starttime:'',
        endtime:'',
        inspection_exception:'',
      },
      queryAreaSelected: [],
      queryInstallDate: null,
      userdepidCascaderSelected: [],
      tableColumns: [
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '名称',
          dataIndex: 'point_name',
          key: 'point_name',
          ellipsis: true,
        },
        {
          title: '巡查人员',
          dataIndex: 'username',
          key: 'username',
          ellipsis: true,
        },
        {
          title: '巡查签到时刻',
          dataIndex: 'inspection_time',
          key: 'inspection_time',
          ellipsis: true,
          scopedSlots: { customRender: 'inspection_time' }
        },
        {
          title: '巡查时长',
          dataIndex: 'inspection_interval',
          key: 'inspection_interval',
          ellipsis: true,
        },
        {
          title: '作业时间状态',
          dataIndex: 'inspection_exception',
          key: 'inspection_exception',
          ellipsis: true,
          scopedSlots: { customRender: 'inspection_exception' }
        },
        {
          title: '巡查结论',
          dataIndex: 'result_code',
          key: 'result_code',
          ellipsis: true,
          scopedSlots: { customRender: 'result_code' }
        },
        {
          title: '签到地址',
          dataIndex: 'inspection_address',
          key: 'inspection_address',
          ellipsis: true,
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 70,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return '巡查记录';
    },
    breadcrumb() {
      return [
        {
          name: '物业巡查',
          path: ''
        },
        {
          name: this.pageTitle,
          path: ''
        },
      ]
    },
  },
  watch: {
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
  },
  created() {
    let time = new Date();
    let monthNum = moment(time).format("YYYY-MM").slice(5);
    this.queryParams.starttime = moment(time).month(monthNum - 1).date(1).startOf("month").format("YYYYMMDD");
    this.queryParams.endtime = moment(time).month(monthNum - 1).date(1).endOf("month").format("YYYYMMDD");
    this.init();
    this.getTableData();
  },
  methods: {
    init() {
      this.getUsernameList();
      this.initDeptOptionsAll();
    },
    getUsernameList(){
      getPropertyInspectionUsernameList({}).then(res=>{
        if(res&&res.returncode==='0'){
          this.usernameList=res.item
        }
      })
    },
    resetQueryParams() {
      this.$refs.queryForm.resetFields();
      this.queryParams.finishsign=""
      this.queryParams.isfinished=""
      this.queryInstallDate = [];
      this.userdepidCascaderSelected=[]
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getPropertyInspectionListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    showModal(type,record){
      if(type=='detail'){
        this.modalDetailData=record
        this.modalShowType=type
        this.modalVisible=true
      }else if(type=='edit'){
        this.modalDetailData=record
        this.modalShowType=type
        this.modalVisible=true
      }
    },
    customRow(record, index){
      return {
        on: { // 事件
          click: () => {
            this.modalDetailData=record
            this.modalShowType='detail'
            this.modalVisible=true
          },
        },
      }
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let inspection_id = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'inspection_id', inspection_id);
      if(type == 'detail') {
        this.modalDetailData=record
        this.modalShowType='detail'
        this.modalVisible=true
      }else if(type == 'delete'){
        this.deleteConfirm(inspection_id, record)
      }
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.inspection_id);
      }).catch(()=>{
      });
    },
    delete(inspection_id) {
      this.showLoading();
      if(inspection_id) {
        let params = {
          inspection_id
        };
        deletePropertyInspection(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
  }
}
</script>
<style lang="scss" scoped>
</style>